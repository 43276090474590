import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonIcon from "@mui/icons-material/Person";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LockIcon from "@mui/icons-material/Lock";
import LogoutIcon from "@mui/icons-material/Logout";
import { Divider, ListItemIcon } from "@mui/material";
import { images } from "../../config/images";
// import LoginIcon from '@mui/icons-material/Login';
// import PersonIcon from '@mui/icons-material/Person';
// import LockIcon from '@mui/icons-material/Lock';
// import { Navigate, useNavigate } from "react-router-dom";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import headerLogo from "../../assets/hikar.png";
import profileImg from "../../assets/images/user_male_icon.png";
import expandScreen from "../../assets/images/expandScreen.png";
import { clearUserDetails, setUserDetails } from "../Login/slice/userSlice";
import { clearProcessDashboarData } from "../ProcessDashboard/Consumption/slice/processDashboardSlice";
import {
  clearProcessDashboarDataSet,
  setProcessDashboarDateSet,
} from "../ProcessDashboard/sliceProcessDashboard/dateSliceprocessDashboard";
import "./Header.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { setHeaderDetails } from "./headerSlice";
import { clearOverviewData } from "../ProcessDashboard/OverView/Slice/OverViewAnalyticsSlice";
import { logoutAPI } from "../Login/service";
import { getAlarmACKCount } from "../ProcessDashboard/service";
import { clearCompanyDetails } from "../SuperAdminDashboard/Slice";
import { ToastContainer, toast } from "react-toastify";
import { setVmsData } from "../VMS/VmsCompanyDashboard/vmsSlice/vmsSlice";
import {
  clearAnalyticsData,
  setAnalyticsData,
} from "../ProcessDashboard/AdvanceAnalytics/Slice/AdvanceAnalyticsSlice";
import { clearVmsReportData } from "../VMS/VmsCompanyDashboard/VmsMachine/Reports/Slice/VmsReportSlice";
import { clearVmsTagData } from "../VMS/VmsCompanyDashboard/VmsMachine/OverView/slice/vmsOverViewSlice";
import { setUserLogin } from "../Login/slice/userLoginSlice";
import { clearSmartTagReportData } from "../SmartTag/MachineDashboard/Slice";
import { clearVmsAdvanceAnalyticsSet } from "../VMS/VmsCompanyDashboard/VmsMachine/Advance Analytics/slice/vmsAdvanceAnalyticsSlice";
import { Session } from "../../components/SessionTimeout/Session";
// import { clearOverviewPOPData } from "../ProcessDashboard/OverView/Slice/POPslice";

export default function Header() {
  const [headerSet, setHeaderSet] = useState();
  const navigate = useNavigate();
  const [confNavigate, setConfnavigate] = useState(false);
  const [alarmCount, setAlarmCount] = useState(0);
  // const [isFullscreen, setIsFullscreen] = useState(false);

  const location = useLocation();

  const {
    firstName,
    lastName,
    email,
    mobileNoRe,
    company_logo,
    is_super_admin,
    user_access,
    redirectHikPred,
    is_created_by_super_admin_user_company,
    token,
  } = useSelector((state) => state.userState);

  const { headerName, headerImageSrc, isFullscreen } = useSelector(
    (state) => state.headerState
  );

  const { dashboard } = useSelector((state) => state.userState);
  const { selectTimeZoneProfile } = useSelector((state) => state.userState);

  const { configureModel } = useSelector((state) => state.vmsState);

  console.log("selectTimeZoneProfile", selectTimeZoneProfile);

  const { configurationSummary } = useSelector(
    (state) => state.analyticsDataState
  );

  const handleSuperAdminDashboard = (superAdmin) => {
    if (!mobileNoRe) {
      return true;
    }
    const newHeaderImageSrc = require("../../assets/images/dashboard.png");
    dispatch(setHeaderDetails({ headerImageSrc: newHeaderImageSrc }));
    if (superAdmin) {
      accessManagementAccess?.is_viewer ||
      accessManagementDevice?.is_viewer ||
      accessManagementSubscription?.is_viewer ||
      accessManagementUser?.is_viewer ||
      accessManagementPlant?.is_viewer ||
      accessManagementUserAlarm?.is_viewer ||
      accessManagementUserReport?.is_viewer ||
      accessClientManagement?.is_viewer
        ? navigate("/superadmindashboard")
        : notifyError("You don't have access");
    } else {
      accessManagementAccess?.is_viewer ||
      accessManagementDevice?.is_viewer ||
      accessManagementSubscription?.is_viewer ||
      accessManagementUser?.is_viewer ||
      accessManagementPlant?.is_viewer ||
      accessManagementUserAlarm?.is_viewer ||
      accessManagementUserReport?.is_viewer ||
      accessClientManagement?.is_viewer
        ? navigate("/admindashboard")
        : notifyError("You don't have access");
    }
  };

  useEffect(() => {
    const splitURL = location.pathname.split("/");
    if (splitURL[4] == "plantprocessdashboard") {
      getAlarmCount(splitURL[5]);
    }
    if (splitURL[1] == "overView") {
      getAlarmCount(splitURL[5]);
    }
    if (splitURL[1] == "overView-analytics") {
      getAlarmCount(splitURL[5]);
    }
    if (splitURL[1] == "source") {
      getAlarmCount(splitURL[5]);
    }
    if (splitURL[1] == "consumption") {
      getAlarmCount(splitURL[5]);
    }
    if (splitURL[1] == "analytics-configration") {
      getAlarmCount(splitURL[5]);
    }
    if (splitURL[1] == "analyticsproduction") {
      getAlarmCount(splitURL[5]);
    }
    if (splitURL[1] == "energycost") {
      getAlarmCount(splitURL[5]);
    }
    if (splitURL[1] == "analyticsproduction") {
      getAlarmCount(splitURL[5]);
    }
    if (splitURL[1] == "vmsmachine") {
      getAlarmCount(splitURL[5]);
    }
    if (splitURL[1] == "submachine") {
      getAlarmCount(splitURL[5]);
    }
    if (splitURL[1] == "vms-overview") {
      getAlarmCount(splitURL[5]);
    }
    if (splitURL[1] == "vms-reports") {
      getAlarmCount(splitURL[5]);
    }
    if (splitURL[1] == "vms-advance-analytics") {
      getAlarmCount(splitURL[5]);
    }
    if (splitURL[1] == "Smarttag-machinedashboard") {
      getAlarmCount(splitURL[5]);
    }
    if (splitURL[1] == "smarttag-machineoverview") {
      getAlarmCount(splitURL[5]);
    }
    if (splitURL[1] == "smarttag-device-tag") {
      getAlarmCount(splitURL[5]);
    }
    if (splitURL[1] == "Smarttag-machinereports") {
      getAlarmCount(splitURL[5]);
    }
    if (splitURL[1] == "Smarttag-machineanalytics") {
      getAlarmCount(splitURL[5]);
    }
  }, [location.pathname]);

  const getAlarmCount = async (plantId) => {
    const paramReq = {
      plant: plantId,
      dashboard: dashboard,
    };

    try {
      const resp = await getAlarmACKCount(paramReq);
      if (resp.status == 200 || (resp.status == 201 && resp.data.success)) {
        setAlarmCount(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  const handleOpenAlarmList = () => {
    navigate(
      `/alarm/company/${
        location.pathname.match(regex)[1]
      }/plant/${location.pathname.substring(
        location.pathname.lastIndexOf("/") + 1
      )}`
    );
    // window.location.reload(false);
  };

  const handleOpenReportAlarmList = () => {
    const regex =
      /\/vms-reports\/company\/([^/]+)\/plant\/([^/]+)\/device\/([^/]+)/;
    const match = location.pathname.match(regex);

    if (match) {
      const companyId = match[1];
      const plantId = match[2];

      navigate(`/alarm/company/${companyId}/plant/${plantId}`);
      // window.location.reload(false);
    }
  };

  const handleOpenadvanceAlarmList = () => {
    const regex =
      /\/vms-advance-analytics\/company\/([^/]+)\/plant\/([^/]+)\/device\/([^/]+)/;
    const match = location.pathname.match(regex);

    if (match) {
      const companyId = match[1];
      const plantId = match[2];

      navigate(`/alarm/company/${companyId}/plant/${plantId}`);
      // window.location.reload(false);
    }
  };

  const handleOpenOverViewAlarmList = () => {
    const regex =
      /\/vms-overview\/company\/([^/]+)\/plant\/([^/]+)\/device\/([^/]+)/;
    const match = location.pathname.match(regex);

    if (match) {
      const companyId = match[1];
      const plantId = match[2];

      navigate(`/alarm/company/${companyId}/plant/${plantId}`);
      // window.location.reload(false);
    }
  };
  const handleOpenAlarmList_submachine = () => {
    navigate(
      `/alarm/company/${
        location.pathname.match(regex)[1]
      }/plant/${location.pathname.substring(
        location.pathname.lastIndexOf("/plant/") + 7,
        location.pathname.lastIndexOf("/device/")
      )}/`
    );
    // window.location.reload(false);
  };

  const handleSmartTagAlarmBtn = () => {
    const regex =
      /\/Smarttag-machinedashboard\/company\/([^/]+)\/plant\/([^/]+)/;
    const match = location.pathname.match(regex);

    if (match) {
      const companyId = match[1];
      const plantId = match[2];

      navigate(`/alarm/company/${companyId}/plant/${plantId}`);
      // window.location.reload(false);
    }
  };
  const handleSmartTagOverviewAlarmBtn = () => {
    const regex =
      /\/smarttag-machineoverview\/company\/([^/]+)\/plant\/([^/]+)/;
    const match = location.pathname.match(regex);

    if (match) {
      const companyId = match[1];
      const plantId = match[2];

      navigate(`/alarm/company/${companyId}/plant/${plantId}`);
      // window.location.reload(false);
    }
  };

  const handlesmartTagDeviceTag = () => {
    const regex = /\/smarttag-device-tag\/company\/([^/]+)\/plant\/([^/]+)/;
    const match = location.pathname.match(regex);

    if (match) {
      const companyId = match[1];
      const plantId = match[2];

      navigate(`/alarm/company/${companyId}/plant/${plantId}`);
      // window.location.reload(false);
    }
  };

  const handlesmartTagReportalarmBtn = () => {
    // alert("kjdskjdnnj")
    // const regex =
    //   /\/Smarttag-machinereports\/company\/([^/]+)\/plant\/([^/]+)\/report/;
    // const match = location.pathname.match(regex);
    // console.log("match", match);
    // if (match) {
    //   const companyId = match[1];
    //   const plantId = match[2];
    //   navigate(`/alarm/company/${companyId}/plant/${plantId}`);
    //   window.location.reload(false);
    // }
  };
  const handlesmartTagAnalyticsalarmBtn = () => {
    const regex =
      /\/Smarttag-machineanalytics\/company\/([^/]+)\/plant\/([^/]+)\/analytics/;
    const match = location.pathname.match(regex);
    console.log("match", match);

    if (match) {
      const companyId = match[1];
      const plantId = match[2];

      navigate(`/alarm/company/${companyId}/plant/${plantId}`);
      // window.location.reload(false);
    }
  };
  const handleshortReportBtn = () => {
    const regex =
      /\/Smarttag-machinereports\/company\/([^/]+)\/plant\/([^/]+)\/report/;
    const match = location.pathname.match(regex);
    console.log("match", match);

    if (match) {
      const companyId = match[1];
      const plantId = match[2];

      navigate(`/alarm/company/${companyId}/plant/${plantId}`);
      // window.location.reload(false);
    }
  };

  const handleshortAnalyticsBtn = () => {
    const regex =
      /\/Smarttag-machineanalytics\/company\/([^/]+)\/plant\/([^/]+)\/analytics/;
    const match = location.pathname.match(regex);
    console.log("match", match);

    if (match) {
      const companyId = match[1];
      const plantId = match[2];

      navigate(`/alarm/company/${companyId}/plant/${plantId}`);
      // window.location.reload(false);
    }
  };

  const headerName1 = {
    // "/superadmindashboard": (
    //   <img
    //     src={require("../../assets/images/dashboard.png")}
    //     onClick={() => navigate("/select")}
    //     alt=""
    //   />
    // ),
    // "/admindashboard": (
    //   <img
    //     src={require("../../assets/images/dashboard.png")}
    //     onClick={() => navigate("/select")}
    //     alt=""
    //   />
    // ),
    "/userprofile": " ",
    "/deviceManagement": is_super_admin
      ? "Super Admin Dashboard"
      : "Admin Dashboard",
    "/plantmanagement": is_super_admin
      ? "Super Admin Dashboard"
      : "Admin Dashboard",
    "/clientmanagement": is_super_admin
      ? "Super Admin Dashboard"
      : "Admin Dashboard",
    "/reportmanagement": is_super_admin
      ? "Super Admin Dashboard"
      : "Admin Dashboard",
    "/accessManagement": is_super_admin
      ? "Super Admin Dashboard"
      : "Admin Dashboard",
    "/addaccessManagement": is_super_admin
      ? "Super Admin Dashboard"
      : "Admin Dashboard",
    "/usermanagement": is_super_admin
      ? "Super Admin Dashboard"
      : "Admin Dashboard",
    "/alarmmanagement": is_super_admin
      ? "Super Admin Dashboard"
      : "Admin Dashboard",
    "/managedepartment": is_super_admin
      ? "Super Admin Dashboard"
      : "Admin Dashboard",
    "/select": " ",
    "/superadmindashboard": " ",
  };

  const regex = /\/(\d+)\//;
  const regex1 = /\/submachine\/company\/\d+\/plant\/(\d+)\/device\/\d+/;

  const { selectUnit, selectCostUnit } = useSelector(
    (state) => state.dateSliceprocessDashboardState
  );

  const handleConfCompanyNavigate = () => {
    if (!confNavigate) {
      navigate(
        `/company/${
          location.pathname.match(regex)[1]
        }/plant/configration/${location.pathname.substring(
          location.pathname.lastIndexOf("/") + 1
        )}`
      );
      setConfnavigate(!confNavigate);
    } else {
      navigate(
        `company/${
          location.pathname.match(regex)[1]
        }/plant/plantprocessdashboard/${location.pathname.substring(
          location.pathname.lastIndexOf("/") + 1
        )}`
      );
      setConfnavigate(!confNavigate);
    }
  };

  const handleConfOverViewNavigate = () => {
    if (!confNavigate) {
      navigate(
        `/overview-configration/company/${
          location.pathname.match(regex)[1]
        }/plant/${location.pathname.substring(
          location.pathname.lastIndexOf("/") + 1
        )}`
      );
      setConfnavigate(!confNavigate);
    } else {
      navigate(
        `/overView/company/${
          location.pathname.match(regex)[1]
        }/plant/${location.pathname.substring(
          location.pathname.lastIndexOf("/") + 1
        )}`
      );
      setConfnavigate(!confNavigate);
    }
  };

  const handleConfConsumption = () => {
    if (!confNavigate) {
      navigate(
        `/consumption-configration/company/${
          location.pathname.match(regex)[1]
        }/plant/${location.pathname.substring(
          location.pathname.lastIndexOf("/") + 1
        )}`
      );
      setConfnavigate(!confNavigate);
    } else {
      navigate(
        `/overView/company/${
          location.pathname.match(regex)[1]
        }/plant/${location.pathname.substring(
          location.pathname.lastIndexOf("/") + 1
        )}`
      );
      setConfnavigate(!confNavigate);
    }
  };
  const plantDashboardAccess = user_access
    ? user_access.length
      ? user_access.find(
          (user) => user.access_module?.access_module_name === "Plant Dashboard"
        )
      : null
    : null;

  const configAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Configuration"
        )
      : null
    : null;

  const analyticsAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Advance Analytics"
        )
      : null
    : null;

  const plantAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Plant Process Dashboard"
        )
      : null
    : null;

  const overViewAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Overview"
        )
      : null
    : null;
  const alarmWindow = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Alarm Window"
        )
      : null
    : null;

  const serviceCall = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Service Call Log"
        )
      : null
    : null;
  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleTostMsg = () => {
    notifyError("You don't have access");
  };
  const headerImages = {
    "/company": (
      <>
        <div>
          <select
            className="compact-dropdown"
            value={selectUnit}
            onChange={(e) =>
              dispatch(
                setProcessDashboarDateSet({ selectUnit: e.target.value })
              )
            }
          >
            <option value="k">Kilo</option>
            <option value="m">Mega</option>
            <option value="g">Giga</option>
          </select>
        </div>
        <div>
          {configAccess?.is_viewer ? (
            <button
              className="configration-btn"
              onClick={() => handleConfCompanyNavigate()}
            >
              <img
                src={images.configration}
                style={{ height: "18px", width: "18px", marginRight: "8px" }}
                alt=""
              />
              Configuration
            </button>
          ) : (
            <button className="configration-btn" onClick={handleTostMsg}>
              <img
                src={images.configration}
                style={{ height: "18px", width: "18px", marginRight: "8px" }}
                alt=""
              />
              Configuration
            </button>
          )}
        </div>

        {(serviceCall?.is_viewer && serviceCall?.is_editor) ||
        (!serviceCall?.is_viewer && serviceCall?.is_editor) ||
        (serviceCall?.is_viewer && !serviceCall?.is_editor) ? (
          <div
            onClick={() =>
              navigate(
                `/servicecall/company/${
                  location.pathname.match(regex)[1]
                }/plant/${location.pathname.substring(
                  location.pathname.lastIndexOf("/") + 1
                )}`
              )
            }
            style={{ pointerEvents: "none" }}
          >
            <img src={images.callImg} />
          </div>
        ) : (
          <div onClick={handleTostMsg} style={{ pointerEvents: "none" }}>
            <img src={images.callImg} />
          </div>
        )}

        {(alarmWindow?.is_viewer && alarmWindow?.is_editor) ||
        (!alarmWindow?.is_viewer && alarmWindow?.is_editor) ||
        (alarmWindow?.is_viewer && !alarmWindow?.is_editor) ? (
          <button
            className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
            onClick={handleOpenAlarmList}
          >
            Alarm
          </button>
        ) : (
          <button
            className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
            // style={{ pointerEvents:"none"}}
            // onClick={() =>
            //   navigate(
            //     `/alarm/company/${location.pathname.match(regex)[1]
            //     }/plant/${location.pathname.substring(
            //       location.pathname.lastIndexOf("/") + 1
            //     )}`

            //   )
            // }
            onClick={handleTostMsg}
          >
            Alarm
          </button>
        )}
      </>
    ),
    "/Company": (
      <>
        <div>
          <button
            className="configration-btn"
            style={{ pointerEvents: "none" }}
          >
            <img
              src={images.configration}
              style={{ height: "18px", width: "18px", marginRight: "8px" }}
            />
            Configuration
          </button>
        </div>
        <button
          className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
          // style={{ pointerEvents:"none"}}
          // onClick={() =>
          //   navigate(
          //     `/alarm/company/${location.pathname.match(regex)[1]
          //     }/plant/${location.pathname.substring(
          //       location.pathname.lastIndexOf("/") + 1
          //     )}`

          //   )
          // }
          onClick={handleOpenAlarmList}
        >
          Alarm
        </button>
      </>
    ),
    "/overView": (
      <>
        <div>
          <select
            className="compact-dropdown"
            value={selectUnit}
            onChange={(e) =>
              dispatch(
                setProcessDashboarDateSet({ selectUnit: e.target.value })
              )
            }
          >
            <option value="k">Kilo</option>
            <option value="m">Mega</option>
            <option value="g">Giga</option>
          </select>
        </div>
        <div>
          {configAccess?.is_viewer ? (
            <button
              className="configration-btn"
              onClick={() => handleConfOverViewNavigate()}
            >
              <img
                src={images.configration}
                style={{ height: "18px", width: "18px", marginRight: "8px" }}
                alt=""
              />
              Configuration
            </button>
          ) : (
            <button className="configration-btn" onClick={handleTostMsg}>
              <img
                src={images.configration}
                style={{ height: "18px", width: "18px", marginRight: "8px" }}
                alt=""
              />
              Configuration
            </button>
          )}
        </div>

        {(alarmWindow?.is_viewer && alarmWindow?.is_editor) ||
        (!alarmWindow?.is_viewer && alarmWindow?.is_editor) ||
        (alarmWindow?.is_viewer && !alarmWindow?.is_editor) ? (
          <button
            className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
            // style={{ pointerEvents:"none"}}
            // onClick={() =>
            //   navigate(
            //     `/alarm/company/${location.pathname.match(regex)[1]
            //     }/plant/${location.pathname.substring(
            //       location.pathname.lastIndexOf("/") + 1
            //     )}`

            //   )
            // }
            onClick={handleOpenAlarmList}
          >
            Alarm
          </button>
        ) : (
          <button
            className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
            // style={{ pointerEvents:"none"}}
            // onClick={() =>
            //   navigate(
            //     `/alarm/company/${location.pathname.match(regex)[1]
            //     }/plant/${location.pathname.substring(
            //       location.pathname.lastIndexOf("/") + 1
            //     )}`

            //   )
            // }
            onClick={handleTostMsg}
          >
            Alarm
          </button>
        )}
      </>
    ),
    "/overview-configration": (
      <>
        <div>
          {configAccess?.is_viewer ? (
            <button
              className="configration-btn"
              onClick={() => handleConfOverViewNavigate()}
            >
              <img
                src={images.configration}
                alt=""
                style={{ height: "18px", width: "18px", marginRight: "8px" }}
              />
              Configuration
            </button>
          ) : (
            <button
              className="configration-btn"
              onClick={() => handleTostMsg()}
            >
              <img
                src={images.configration}
                alt=""
                style={{ height: "18px", width: "18px", marginRight: "8px" }}
              />
              Configuration
            </button>
          )}
        </div>

        <button
          className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
          // style={{ pointerEvents:"none"}}
          // onClick={() =>
          //   navigate(
          //     `/alarm/company/${location.pathname.match(regex)[1]
          //     }/plant/${location.pathname.substring(
          //       location.pathname.lastIndexOf("/") + 1
          //     )}`

          //   )
          // }
          onClick={handleOpenAlarmList}
        >
          Alarm
        </button>
      </>
    ),
    "/source": (
      <>
        <div>
          <select
            className="compact-dropdown"
            value={selectUnit}
            onChange={(e) =>
              dispatch(
                setProcessDashboarDateSet({ selectUnit: e.target.value })
              )
            }
          >
            <option value="k">Kilo</option>
            <option value="m">Mega</option>
            <option value="g">Giga</option>
          </select>
        </div>
        <div>
          {configAccess?.is_viewer ? (
            <button
              className="configration-btn"
              onClick={() =>
                navigate(
                  `/configration-source/source/company/${
                    location.pathname.match(regex)[1]
                  }/plant/${location.pathname.substring(
                    location.pathname.lastIndexOf("/") + 1
                  )}`
                )
              }
            >
              <img
                src={images.configration}
                style={{ height: "18px", width: "18px", marginRight: "8px" }}
              />
              Configuration
            </button>
          ) : (
            <button className="configration-btn" onClick={handleTostMsg}>
              <img
                src={images.configration}
                style={{ height: "18px", width: "18px", marginRight: "8px" }}
              />
              Configuration
            </button>
          )}
        </div>
        {(alarmWindow?.is_viewer && alarmWindow?.is_editor) ||
        (!alarmWindow?.is_viewer && alarmWindow?.is_editor) ||
        (alarmWindow?.is_viewer && !alarmWindow?.is_editor) ? (
          <button
            className="alarm-button"
            onClick={() =>
              navigate(
                `/source-alarm/company/${
                  location.pathname.match(regex)[1]
                }/plant/${location.pathname.substring(
                  location.pathname.lastIndexOf("/") + 1
                )}`
              )
            }
          >
            Alarm
          </button>
        ) : (
          <button className="alarm-button" onClick={handleTostMsg}>
            Alarm
          </button>
        )}
      </>
    ),
    "/configration-source": (
      <>
        <div>
          {configAccess?.is_viewer ? (
            <button
              className="configration-btn"
              onClick={() =>
                navigate(
                  `/source/company/${
                    location.pathname.match(regex)[1]
                  }/plant/${location.pathname.substring(
                    location.pathname.lastIndexOf("/") + 1
                  )}`
                )
              }
            >
              <img
                src={images.configration}
                style={{ height: "18px", width: "18px", marginRight: "8px" }}
                alt=""
              />
              Configuration
            </button>
          ) : (
            <button className="configration-btn" onClick={handleTostMsg}>
              <img
                src={images.configration}
                style={{ height: "18px", width: "18px", marginRight: "8px" }}
                alt=""
              />
              Configuration
            </button>
          )}
        </div>

        <button
          className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
          // style={{ pointerEvents:"none"}}
          // onClick={() =>
          //   navigate(
          //     `/alarm/company/${location.pathname.match(regex)[1]
          //     }/plant/${location.pathname.substring(
          //       location.pathname.lastIndexOf("/") + 1
          //     )}`

          //   )
          // }
          onClick={handleOpenAlarmList}
        >
          Alarm
        </button>
      </>
    ),
    "/consumption": (
      <>
        <div>
          <select
            className="compact-dropdown"
            value={selectUnit}
            onChange={(e) =>
              dispatch(
                setProcessDashboarDateSet({ selectUnit: e.target.value })
              )
            }
          >
            <option value="k">Kilo</option>
            <option value="m">Mega</option>
            <option value="g">Giga</option>
          </select>
        </div>
        <div>
          {configAccess?.is_viewer ? (
            <button
              className="configration-btn"
              onClick={() =>
                navigate(
                  `/consumption-configration/company/${
                    location.pathname.match(regex)[1]
                  }/plant/${location.pathname.substring(
                    location.pathname.lastIndexOf("/") + 1
                  )}`
                )
              }
            >
              <img
                src={images.configration}
                style={{ height: "18px", width: "18px", marginRight: "8px" }}
                alt=""
              />
              Configuration
            </button>
          ) : (
            <button className="configration-btn" onClick={handleTostMsg}>
              <img
                src={images.configration}
                style={{ height: "18px", width: "18px", marginRight: "8px" }}
                alt=""
              />
              Configuration
            </button>
          )}
        </div>

        {(alarmWindow?.is_viewer && alarmWindow?.is_editor) ||
        (!alarmWindow?.is_viewer && alarmWindow?.is_editor) ||
        (alarmWindow?.is_viewer && !alarmWindow?.is_editor) ? (
          <button
            className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
            // style={{ pointerEvents:"none"}}
            // onClick={() =>
            //   navigate(
            //     `/alarm/company/${location.pathname.match(regex)[1]
            //     }/plant/${location.pathname.substring(
            //       location.pathname.lastIndexOf("/") + 1
            //     )}`

            //   )
            // }
            onClick={handleOpenAlarmList}
          >
            Alarm
          </button>
        ) : (
          <button
            className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
            // style={{ pointerEvents:"none"}}
            // onClick={() =>
            //   navigate(
            //     `/alarm/company/${location.pathname.match(regex)[1]
            //     }/plant/${location.pathname.substring(
            //       location.pathname.lastIndexOf("/") + 1
            //     )}`

            //   )
            // }
            onClick={handleTostMsg}
          >
            Alarm
          </button>
        )}
      </>
    ),
    "/consumption-configration": (
      <>
        <div>
          {configAccess?.is_viewer ? (
            <button
              className="configration-btn"
              onClick={() =>
                navigate(
                  `/consumption/company/${
                    location.pathname.match(regex)[1]
                  }/plant/${location.pathname.substring(
                    location.pathname.lastIndexOf("/") + 1
                  )}`
                )
              }
            >
              <img
                src={images.configration}
                style={{ height: "18px", width: "18px", marginRight: "8px" }}
              />
              Configuration
            </button>
          ) : (
            <button className="configration-btn" onClick={handleTostMsg}>
              <img
                src={images.configration}
                style={{ height: "18px", width: "18px", marginRight: "8px" }}
              />
              Configuration
            </button>
          )}
        </div>

        <button
          className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
          // style={{ pointerEvents:"none"}}
          // onClick={() =>
          //   navigate(
          //     `/alarm/company/${location.pathname.match(regex)[1]
          //     }/plant/${location.pathname.substring(
          //       location.pathname.lastIndexOf("/") + 1
          //     )}`

          //   )
          // }
          onClick={handleOpenAlarmList}
        >
          Alarm
        </button>
      </>
    ),
    "/smarTagLiveGraph": (
      <>
        <div>
          <select
            className="compact-dropdown"
            value={selectUnit}
            onChange={(e) =>
              dispatch(
                setProcessDashboarDateSet({ selectUnit: e.target.value })
              )
            }
          >
            <option value="k">Kilo</option>
            <option value="m">Mega</option>
            <option value="g">Giga</option>
          </select>
        </div>
      </>
    ),
    "/analytics": (
      <>
        <div>
          {configAccess?.is_viewer ? (
            <button
              className="configration-btn"
              style={{ marginRight: "-13px" }}
              onClick={() =>
                navigate(
                  `/analytics-configration/company/${
                    location.pathname.match(regex)[1]
                  }/plant/${location.pathname.substring(
                    location.pathname.lastIndexOf("/") + 1
                  )}`
                )
              }
            >
              <img
                src={images.configration}
                style={{ height: "18px", width: "18px", marginRight: "8px" }}
              />
              Configuration
            </button>
          ) : (
            <button
              className="configration-btn"
              style={{ marginRight: "-13px" }}
              onClick={handleTostMsg}
            >
              <img
                src={images.configration}
                style={{ height: "18px", width: "18px", marginRight: "8px" }}
                alt=""
              />
              Configuration
            </button>
          )}
        </div>

        <button
          className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
          // style={{ pointerEvents:"none"}}
          // onClick={() =>
          //   navigate(
          //     `/alarm/company/${location.pathname.match(regex)[1]
          //     }/plant/${location.pathname.substring(
          //       location.pathname.lastIndexOf("/") + 1
          //     )}`

          //   )
          // }
          onClick={handleOpenAlarmList}
        >
          Alarm
        </button>
      </>
    ),
    "/analytics-configration": (
      <>
        <div>
          <select
            className="compact-dropdown"
            value={selectUnit}
            onChange={(e) =>
              dispatch(
                setProcessDashboarDateSet({ selectUnit: e.target.value })
              )
            }
          >
            <option value="k">Kilo</option>
            <option value="m">Mega</option>
            <option value="g">Giga</option>
          </select>
        </div>
        <div>
          {configAccess?.is_viewer ? (
            <button
              className="configration-btn exclude-me"
              style={{ marginRight: "-13px" }}
              onClick={() =>
                dispatch(
                  setAnalyticsData({
                    configurationSummary: !configurationSummary,
                  })
                )
              }
            >
              <img
                src={images.configration}
                alt=""
                style={{ height: "18px", width: "18px", marginRight: "8px" }}
              />
              Configuration
            </button>
          ) : (
            <button
              className="configration-btn exclude-me"
              style={{ marginRight: "-13px" }}
              onClick={handleTostMsg}
            >
              <img
                src={images.configration}
                alt=""
                style={{ height: "18px", width: "18px", marginRight: "8px" }}
              />
              Configuration
            </button>
          )}
        </div>
        {(alarmWindow?.is_viewer && alarmWindow?.is_editor) ||
        (!alarmWindow?.is_viewer && alarmWindow?.is_editor) ||
        (alarmWindow?.is_viewer && !alarmWindow?.is_editor) ? (
          <button
            className={
              alarmCount == 0
                ? "alarm-btn exclude-me"
                : "alarm-button exclude-me"
            }
            // style={{ pointerEvents:"none"}}
            // onClick={() =>
            //   navigate(
            //     `/alarm/company/${location.pathname.match(regex)[1]
            //     }/plant/${location.pathname.substring(
            //       location.pathname.lastIndexOf("/") + 1
            //     )}`

            //   )
            // }
            onClick={handleOpenAlarmList}
          >
            Alarm
          </button>
        ) : (
          <button
            className={
              alarmCount == 0
                ? "alarm-btn exclude-me"
                : "alarm-button exclude-me"
            }
            // style={{ pointerEvents:"none"}}
            // onClick={() =>
            //   navigate(
            //     `/alarm/company/${location.pathname.match(regex)[1]
            //     }/plant/${location.pathname.substring(
            //       location.pathname.lastIndexOf("/") + 1
            //     )}`

            //   )
            // }
            onClick={handleTostMsg}
          >
            Alarm
          </button>
        )}
      </>
    ),
    "/analyticsproduction/company/:companyId/plant/:id": (
      <>
        {/* <div>
          <button className="configration-btn" style={{ pointerEvents: "none" }}><img src={images.configration} style={{ height: "18px", width: "18px", marginRight: "8px" }} />Configuration</button>
        </div> */}
        {(alarmWindow?.is_viewer && alarmWindow?.is_editor) ||
        (!alarmWindow?.is_viewer && alarmWindow?.is_editor) ||
        (alarmWindow?.is_viewer && !alarmWindow?.is_editor) ? (
          <button
            className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
            // style={{ pointerEvents:"none"}}
            // onClick={() =>
            //   navigate(
            //     `/alarm/company/${location.pathname.match(regex)[1]
            //     }/plant/${location.pathname.substring(
            //       location.pathname.lastIndexOf("/") + 1
            //     )}`

            //   )
            // }
            onClick={handleOpenAlarmList}
          >
            Alarm
          </button>
        ) : (
          <button
            className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
            // style={{ pointerEvents:"none"}}
            // onClick={() =>
            //   navigate(
            //     `/alarm/company/${location.pathname.match(regex)[1]
            //     }/plant/${location.pathname.substring(
            //       location.pathname.lastIndexOf("/") + 1
            //     )}`

            //   )
            // }
            onClick={handleTostMsg}
          >
            Alarm
          </button>
        )}
      </>
    ),
    "/energycost": (
      <>
        {/* <div>
          <button className="configration-btn" style={{ pointerEvents: "none" }} ><img src={images.configration} style={{ height: "18px", width: "18px", marginRight: "8px" }} />Configuration</button>
        </div> */}
        <div>
          <select
            className="compact-dropdown"
            value={selectCostUnit}
            onChange={(e) =>
              dispatch(
                setProcessDashboarDateSet({ selectCostUnit: e.target.value })
              )
            }
          >
            <option selected value="">
              Select Unit
            </option>
            <option value="k">Thousand</option>
            <option value="m">Million</option>
            <option value="b">Billion</option>
          </select>
        </div>
        {(alarmWindow?.is_viewer && alarmWindow?.is_editor) ||
        (!alarmWindow?.is_viewer && alarmWindow?.is_editor) ||
        (alarmWindow?.is_viewer && !alarmWindow?.is_editor) ? (
          <button
            className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
            // style={{ pointerEvents:"none"}}
            // onClick={() =>
            //   navigate(
            //     `/alarm/company/${location.pathname.match(regex)[1]
            //     }/plant/${location.pathname.substring(
            //       location.pathname.lastIndexOf("/") + 1
            //     )}`

            //   )
            // }
            onClick={handleOpenAlarmList}
          >
            Alarm
          </button>
        ) : (
          <button
            className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
            // style={{ pointerEvents:"none"}}
            // onClick={() =>
            //   navigate(
            //     `/alarm/company/${location.pathname.match(regex)[1]
            //     }/plant/${location.pathname.substring(
            //       location.pathname.lastIndexOf("/") + 1
            //     )}`

            //   )
            // }
            onClick={handleTostMsg}
          >
            Alarm
          </button>
        )}
      </>
    ),
    "/CO2": (
      <>
        {/* <div>
          <button className="configration-btn" style={{ pointerEvents: "none" }}><img src={images.configration} style={{ height: "18px", width: "18px", marginRight: "8px" }} />Configuration</button>
        </div> */}
        {(alarmWindow?.is_viewer && alarmWindow?.is_editor) ||
        (!alarmWindow?.is_viewer && alarmWindow?.is_editor) ||
        (alarmWindow?.is_viewer && !alarmWindow?.is_editor) ? (
          <button
            className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
            // style={{ pointerEvents:"none"}}
            // onClick={() =>
            //   navigate(
            //     `/alarm/company/${location.pathname.match(regex)[1]
            //     }/plant/${location.pathname.substring(
            //       location.pathname.lastIndexOf("/") + 1
            //     )}`

            //   )
            // }
            onClick={handleOpenAlarmList}
          >
            Alarm
          </button>
        ) : (
          <button
            className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
            // style={{ pointerEvents:"none"}}
            // onClick={() =>
            //   navigate(
            //     `/alarm/company/${location.pathname.match(regex)[1]
            //     }/plant/${location.pathname.substring(
            //       location.pathname.lastIndexOf("/") + 1
            //     )}`

            //   )
            // }
            onClick={handleTostMsg}
          >
            Alarm
          </button>
        )}
      </>
    ),

    "/submachine": (
      <>
        {configAccess?.is_viewer ? (
          <button
            className="configration-btn"
            style={{ marginRight: "-13px" }}
            onClick={() =>
              dispatch(setVmsData({ configureModel: !configureModel }))
            }
          >
            <img
              src={images.configration}
              style={{ height: "18px", width: "18px", marginRight: "8px" }}
              alt=""
            />
            Configuration
          </button>
        ) : (
          <button
            className="configration-btn"
            style={{ marginRight: "-13px" }}
            onClick={handleTostMsg}
          >
            <img
              src={images.configration}
              style={{ height: "18px", width: "18px", marginRight: "8px" }}
              alt=""
            />
            Configuration
          </button>
        )}

        {(alarmWindow?.is_viewer && alarmWindow?.is_editor) ||
        (!alarmWindow?.is_viewer && alarmWindow?.is_editor) ||
        (alarmWindow?.is_viewer && !alarmWindow?.is_editor) ? (
          <button
            className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
            onClick={handleOpenAlarmList_submachine}
          >
            Alarm
          </button>
        ) : (
          <button
            className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
            onClick={handleTostMsg}
          >
            Alarm
          </button>
        )}
      </>
    ),
    "/vms-overview": (
      <>
        {(alarmWindow?.is_viewer && alarmWindow?.is_editor) ||
        (!alarmWindow?.is_viewer && alarmWindow?.is_editor) ||
        (alarmWindow?.is_viewer && !alarmWindow?.is_editor) ? (
          <button
            className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
            onClick={handleOpenOverViewAlarmList}
          >
            Alarm
          </button>
        ) : (
          <button
            className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
            onClick={handleTostMsg}
          >
            Alarm
          </button>
        )}
      </>
    ),
    "/vmsmachine":
      (alarmWindow?.is_viewer && alarmWindow?.is_editor) ||
      (!alarmWindow?.is_viewer && !alarmWindow?.is_editor) ||
      (alarmWindow?.is_viewer && !alarmWindow?.is_editor) ? (
        <button
          className={alarmCount === 0 ? "alarm-btn" : "alarm-button"}
          onClick={handleOpenAlarmList}
        >
          Alarm
        </button>
      ) : (
        <button
          className={alarmCount === 0 ? "alarm-btn" : "alarm-button"}
          onClick={handleTostMsg}
        >
          Alarm
        </button>
      ),
    "/vms-reports":
      (alarmWindow?.is_viewer && alarmWindow?.is_editor) ||
      (!alarmWindow?.is_viewer && !alarmWindow?.is_editor) ||
      (alarmWindow?.is_viewer && !alarmWindow?.is_editor) ? (
        <button
          className={alarmCount === 0 ? "alarm-btn" : "alarm-button"}
          onClick={handleOpenReportAlarmList}
        >
          Alarm
        </button>
      ) : (
        <button
          className={alarmCount === 0 ? "alarm-btn" : "alarm-button"}
          onClick={handleTostMsg}
        >
          Alarm
        </button>
      ),
    "/vms-advance-analytics":
      (alarmWindow?.is_viewer && alarmWindow?.is_editor) ||
      (!alarmWindow?.is_viewer && !alarmWindow?.is_editor) ||
      (alarmWindow?.is_viewer && !alarmWindow?.is_editor) ? (
        <button
          className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
          onClick={handleOpenadvanceAlarmList}
        >
          Alarm
        </button>
      ) : (
        <button
          className={alarmCount === 0 ? "alarm-btn" : "alarm-button"}
          onClick={handleTostMsg}
        >
          Alarm
        </button>
      ),

    "/overView-analytics": (
      <>
        <div>
          <select
            className="compact-dropdown"
            value={selectUnit}
            onChange={(e) =>
              dispatch(
                setProcessDashboarDateSet({ selectUnit: e.target.value })
              )
            }
          >
            <option value="k">Kilo</option>
            <option value="m">Mega</option>
            <option value="g">Giga</option>
          </select>
        </div>
        <div>
          <>
            {(alarmWindow?.is_viewer && alarmWindow?.is_editor) ||
            (!alarmWindow?.is_viewer && !alarmWindow?.is_editor) ||
            (alarmWindow?.is_viewer && !alarmWindow?.is_editor) ? (
              <button
                className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
                onClick={handleOpenAlarmList}
              >
                Alarm
              </button>
            ) : (
              <button
                className={alarmCount === 0 ? "alarm-btn" : "alarm-button"}
                onClick={handleTostMsg}
              >
                Alarm
              </button>
            )}
          </>
        </div>
      </>
    ),

    "/analyticsproduction":
      (alarmWindow?.is_viewer && alarmWindow?.is_editor) ||
      (!alarmWindow?.is_viewer && !alarmWindow?.is_editor) ||
      (alarmWindow?.is_viewer && !alarmWindow?.is_editor) ? (
        <button
          className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
          onClick={handleOpenAlarmList}
        >
          Alarm
        </button>
      ) : (
        <button
          className={alarmCount === 0 ? "alarm-btn" : "alarm-button"}
          onClick={handleTostMsg}
        >
          Alarm
        </button>
      ),

    "/Smarttag-machinedashboard": (
      <button
        className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
        onClick={handleSmartTagAlarmBtn}
      >
        Alarm
      </button>
    ),
    "/smarttag-machineoverview": (
      <button
        className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
        onClick={handleSmartTagOverviewAlarmBtn}
      >
        Alarm
      </button>
    ),
    "/smarttag-device-tag": (
      <button
        className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
        onClick={handlesmartTagDeviceTag}
      >
        Alarm
      </button>
    ),
    "/Smarttag-machinereports": (
      <button
        className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
        onClick={handlesmartTagReportalarmBtn}
      >
        Alarm
      </button>
    ),
    "/Smarttag-machineanalytics": (
      <button
        className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
        onClick={handlesmartTagAnalyticsalarmBtn}
      >
        Alarm
      </button>
    ),
    "/Smarttag-machinereports": (
      <button
        className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
        onClick={handleshortReportBtn}
      >
        Alarm
      </button>
    ),
    "/Smarttag-machineanalytics": (
      <button
        className={alarmCount == 0 ? "alarm-btn" : "alarm-button"}
        onClick={handleshortAnalyticsBtn}
      >
        Alarm
      </button>
    ),
  };

  useEffect(() => {
    const end = location.pathname.indexOf("/", 1);
    const dynamicPart = location.pathname.substring(0, end);
    setHeaderSet(dynamicPart ? dynamicPart : location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const kp = { name: "khushal" };

    const newKp = Object.keys(kp).reduce((acc, key) => {
      if (key === "name") {
        acc["fullName"] = kp[key];
      } else {
        acc[key] = kp[key];
      }
      return acc;
    }, {});
  }, []);

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = async () => {
    const params = {
      token: token,
    };

    try {
      const resp = await logoutAPI(params);
      if (resp.status === 200 || resp.status === 201) {
        dispatch(setUserLogin({ userName: null }));
        dispatch(clearUserDetails());
        dispatch(clearSmartTagReportData());
        dispatch(clearAnalyticsData());
        dispatch(clearProcessDashboarData());
        dispatch(clearOverviewData());
        dispatch(clearProcessDashboarDataSet());
        // dispatch(clearVmsTagData());
        dispatch(clearVmsReportData());
        dispatch(clearVmsAdvanceAnalyticsSet());
        navigate("/");
        window.location.reload(false);
        localStorage.clear();
      } else {
        console.error("Logout failed with status:", resp.status);
        // Handle other status codes if needed
      }
    } catch (error) {
      console.error("Error occurred during logout:", error);
      // Handle error in logout process
    }
  };

  const openFullscreen = () => {
    const element = document.documentElement; // Adjust this selector as needed

    if (!isFullscreen) {
      try {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
        dispatch(setHeaderDetails({ isFullscreen: true }));
      } catch (error) {
        console.error("Fullscreen request failed:", error);
      }
    } else {
      try {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        dispatch(setHeaderDetails({ isFullscreen: false }));
      } catch (error) {
        console.error("Fullscreen exit failed:", error);
      }
    }
  };
  const defaultTimeZone = "Asia/Kolkata"; // Default to a valid time zone if needed

  useEffect(() => {
    const updateDateTime = (timeZone) => {
      const tempDate = new Date();
      const options = {
        timeZone: timeZone || defaultTimeZone, // Use the selected time zone
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false, // 24-hour format
      };

      const formatter = new Intl.DateTimeFormat("en-US", options);

      document.getElementById("currentDateTime").textContent =
        formatter.format(tempDate);
    };

    updateDateTime(selectTimeZoneProfile);

    const intervalId = setInterval(
      () => updateDateTime(selectTimeZoneProfile),
      1000
    );

    return () => clearInterval(intervalId);
  }, [selectTimeZoneProfile]); // Re-run effect when selectTimeZoneProfile changes

  const superAdminAccess = user_access.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Admin Dashboard"
      )
    : null;

  const userProfileAccess = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
          (f) => f.sub_module_name == "User Profile"
        )
      : null
    : null;

  const CSVAccess = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
          (f) => f.sub_module_name == "Upload Manual Data CSV"
        )
      : null
    : null;

  const accessManagementAccess = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
          (f) => f.sub_module_name == "Access Management"
        )
      : null
    : null;
  const accessManagementDevice = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
          (f) => f.sub_module_name == "Device Management"
        )
      : null
    : null;
  const accessManagementSubscription = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
          (f) => f.sub_module_name == "Subscription Management"
        )
      : null
    : null;
  const accessManagementUser = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
          (f) => f.sub_module_name == "User Management"
        )
      : null
    : null;
  const accessManagementPlant = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
          (f) => f.sub_module_name == "Plant Management"
        )
      : null
    : null;
  const accessManagementUserAlarm = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
          (f) => f.sub_module_name == "Alarm Management"
        )
      : null
    : null;
  const accessManagementUserReport = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
          (f) => f.sub_module_name == "Report Management"
        )
      : null
    : null;
  const accessClientManagement = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
          (f) => f.sub_module_name == "Client Management"
        )
      : null
    : null;

  return (
    <>
      <Session />
      <AppBar position="static" className="headerWrap">
        <Container maxWidth="none">
          <Toolbar disableGutters className="toolbarWrap">
            <div className="headerTitle-wrap">
              <Typography
                variant="h6"
                noWrap
                component="a"
                onClick={() => {
                  if (!mobileNoRe) {
                    return true;
                  }

                  if (redirectHikPred == "Vms") {
                    navigate("/vms");
                    return true;
                  }

                  if (redirectHikPred == "Smarttag") {
                    navigate("/Smarttag-Dashboard");
                    return true;
                  }
                  navigate("/dashboard");
                }}
              >
                {company_logo ? (
                  <img src={company_logo} alt="logo" className="headerLogo" />
                ) : (
                  <img src={headerLogo} alt="logo" className="headerLogo" />
                )}
              </Typography>
              <p className="headerTitle">
                {headerName1[location.pathname] ? (
                  headerName1[location.pathname]
                ) : (
                  <div onClick={() => window.history.back()}>{headerName}</div>
                )}
              </p>
            </div>
            <Box sx={{ flexGrow: 0 }} className="headerToolbar-wrap">
              {headerImages[headerSet]}
              <div className="dateTime-wrap">
                <p id="currentDateTime"></p>
              </div>
              <div
                className="expandScreen-btn exclude-me"
                onClick={openFullscreen}
                style={{ cursor: "pointer" }}
              >
                {isFullscreen ? (
                  <img src={images.minimizeScreen} alt="Minimize Screen" />
                ) : (
                  <img src={expandScreen} alt="Expand Screen" />
                )}
              </div>

              <div
                onClick={handleClick}
                className="profileToggle-menu exclude-me"
              >
                <Avatar
                  alt=""
                  src={profileImg}
                  className="profile-toggle-img"
                />
                <p className="profileName">
                  {firstName} {lastName}
                </p>
                <KeyboardArrowDownIcon className="ToggleIcon" />
              </div>

              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgb(84, 108, 138))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      // content: '""',
                      // display: "block",
                      // position: "absolute",
                      // top: 0,
                      // right: 46,
                      // width: 29,
                      // height: 28,
                      // bgcolor: "background.paper",
                      // transform: "translateY(-50%) rotate(45deg)",
                      // zIndex: 0,
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: -27,
                      right: 0,
                      width: 0,
                      height: 0,
                      borderLeft: "25px solid transparent",
                      borderRight: "25px solid transparent",
                      borderBottom: "50px solid #fff",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem>
                  <div className="profile-item">
                    <Avatar alt="" src={profileImg} className="profile-image" />
                    <div className="profile-detail">
                      <p className="userName">
                        {firstName} {lastName}
                      </p>
                      <p className="userEmail">{email}</p>
                    </div>
                  </div>
                </MenuItem>

                <Divider />
                <MenuItem
                  className="menu-item"
                  sx={{ px: 3, py: 2 }}
                  onClick={() => {
                    if (userProfileAccess?.is_viewer) {
                      navigate("/userprofile");
                    } else {
                      notifyError("You don't have access");
                      return true;
                    }
                  }}
                >
                  <ListItemIcon>
                    <PersonIcon fontSize="small" />
                  </ListItemIcon>
                  Profile
                </MenuItem>
                <MenuItem
                  className="menu-item"
                  sx={{ px: 3, py: 2 }}
                  onClick={() => {
                    if(CSVAccess?.is_viewer){
                      navigate("/uploadManualData");
                    }
                    else{
                      notifyError("You don't have access");
                      return true;
                    }
                  
                  }}
                >
                  <ListItemIcon>
                    <UploadFileIcon fontSize="small" />
                  </ListItemIcon>
                  Upload manual data CSV files
                </MenuItem>
                <MenuItem
                  className="menu-item"
                  sx={{ px: 3, py: 2 }}
                  onClick={() => handleSuperAdminDashboard(is_super_admin)}
                >
                  <ListItemIcon>
                    <DashboardIcon fontSize="small" />
                  </ListItemIcon>
                  {is_super_admin || is_created_by_super_admin_user_company
                    ? "Super"
                    : ""}{" "}
                  Admin Dashboard
                </MenuItem>
                <MenuItem
                  className="menu-item"
                  sx={{ px: 3, py: 2 }}
                  onClick={() => {
                    if (!mobileNoRe) {
                      return true;
                    }

                    navigate("/change-password");
                  }}
                >
                  <ListItemIcon>
                    <img src={images.passwordImg} alt="" class />
                  </ListItemIcon>
                  Change Password
                </MenuItem>
                <MenuItem
                  className="menu-item"
                  sx={{ px: 3, py: 2 }}
                  onClick={() => handleLogOut()}
                >
                  <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
